import { Logo } from './icons/logo';

export default function Header ({ userName }) {
  return (
    <header className="bg-app-black py-4 flex justify-between px-5">
      <a className="justify-start" href="/">
        <Logo />
      </a>
      <p className="text-white">{userName}</p>
    </header>
  );
}
