import {
  Links,
  LiveReload,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'@remix-run/react';

import Header from './components/header';

import styles from '../public/styles/main.css';
import getEnv from './env.server';

export function links() {
  return [
  { rel: 'stylesheet', href: styles },
  { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
  { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
  { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
  { rel: 'mask-icon', color: '#000000', href: '/safari-pinned-tab.svg' }];

}

function Document({ children, userName, ENV }) {
  return (
    <html lang="en">
      <head>
        <title>PushApp</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body>
        <Header userName={userName} />
        <main className="relative">
          {children}
          <ScrollRestoration />
          <Scripts />
          <script dangerouslySetInnerHTML={{ __html: `window.ENV = ${JSON.stringify(ENV)}` }} />
          <LiveReload />
        </main>
      </body>
    </html>);

}

export default function App() {
  const { userName, ENV } = useLoaderData();
  return (
    <Document userName={userName} ENV={ENV}>
      <Outlet />
    </Document>);

}

export function ErrorBoundary({ error }) {
  return (
    <Document>
      <div>
        <h1>Error at root {error}</h1>
      </div>
    </Document>);

}

export function loader({ context }) {
  return {
    userName: context?.userName || 'User',
    ENV: getEnv()
  };
}